<template>
  <div class="modal fade" id="privacyPolicyModal" tabindex="-1" aria-labelledby="privacyPolicyModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" id="privacyPolicyModalLabel">개인정보처리방침</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <p>reviewmoa.gg('이하 'IT리뷰모아')는 회원님의 개인정보를 중요시하며, 개인정보의 보호와 관련하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 전기통신사업법, 통신비밀보호법 등 개인정보와 관련된 법령 상의 개인정보 보호 규정 및 방송통신위원회가 제정한 개인정보보호지침을 준수하고 있습니다.</p>


          <p>'IT리뷰모아'는 개인정보취급방침을 통하여 회원님들의 개인정보가 남용되거나 유출되지 않도록 최선을 다할 것이며, 회원님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있고, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드리고자 합니다.</p>

          <p>단, 본 개인정보 취급방침은 정부의 법령 및 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있으니, 회원님들께서는 사이트 방문 시 수시로 그 내용을 확인하여 주시기 바라며, 'IT리뷰모아'는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>

          <p>[1. 수집하는 개인정보 항목]</p>
          <p>
            'IT리뷰모아'는 회원가입, 서비스이용 등을 위해 아래와 같은 개인정보를 수집하고 있으며 필요한 최소한의 정보만을 수집합니다.
            수집 항목: ID, 이메일 주소, 서비스이용기록, 쿠키
          </p>

          <p>[2. 개인정보의 수집 및 이용목적]</p>

          <p>
            (가). 'IT리뷰모아'가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용의사를 확인하여 최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 'IT리뷰모아'는 최초 회원가입 시 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있습니다.
          </p>

          <p>
            (나). 'IT리뷰모아'는 개인정보를 수집 및 이용목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다.
          </p>

          <p>
            (다). 'IT리뷰모아'는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
          </p>

          <p>
            (라). 당사 데이터베이스를 최신으로 유지하고 귀하에게 가장 연관성이 높은 콘텐츠와 경험을 제공하기 위해, 당사는 귀하가 제공하는 정보를 해당 법률에 따라 서드파티 소스의 정보와 조합할 수 있습니다. 기타 정보를 전문 네트워킹 사이트 및 정보 서비스 공급자 등의 소스에서 얻을 수 있습니다. 또한 사기, 보안 또는 기술 문제의 감지, 예방 또는 해결 및 서비스 이용과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보 등이 생성되어 수집될 수 있습니다.
          </p>

          <p>
            (마). 'IT리뷰모아'는 이용자의 개인정보를 수집할 경우 반드시 이용자의 동의를 얻어 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 인종, 출신지, 본적지, 사상, 정치적 성향, 범죄기록, 건강상태 등의 정보는 이용자의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.
          </p>


          <p>[3. 개인정보의 보유, 이용기간 및 파기]</p>
          <p>
            'IT리뷰모아'는 서비스활성 사용자인 동안 보유한 대부분의 개인 정보를 처리하여 보관합니다. 계정이 폐쇄되면 'IT리뷰모아'는 계정 데이터 같이 이용상 보유할 이유가 없게 된 특정 개인 정보를 삭제하기 시작합니다. 하지만 일반적으로 서비스이용과 관련된 개인 정보는 마지막으로 상호 작용한 이후 최대 1년간 보유됩니다.
            전자적 파일 형태의 개인정보는 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다. 한편 'IT리뷰모아'는 대한민국 관계 법령에서 정한 의무 보유 기간을 충족하는 기간 동안 아래와 같은 개인정보를 보관합니다.
            소비자의 불만 또는 분쟁처리에 관한 기록 표시•광고에 관한 기록 통신비밀보호법 로그 기록
          </p>

          <p>[4. 개인정보의 제공]</p>
          <p>'IT리뷰모아'는 고객님의 개인정보를 원칙적으로 외부에 제공하지 않습니다.</p>

          <p>[5. 정보주체의 권리, 의무 및 그 행사방법]</p>
          <p>
            이용자는 개인정보의 보호와 관련된 개인정보의 열람, 정정, 삭제, 처리의 정지를 요구 할 수 있는 권리가 있으며, 'IT리뷰모아'는 이용자의 요구가 있을 시 지체 없이 처리하겠습니다. 이에 따른 권리 행사는 서면, 전화, 이메일 등을 통하여 할 수 있으며, 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통해서도 가능합니다.
            이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 'IT리뷰모아'는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다.
            이용자 및 법정대리인은 개인정보를 보호받을 권리와 함께 스스로 개인정보를 보호하고 타인의 개인정보를 침해하지 않을 의무도 가지고 있으므로, 이용자 및 법정대리인의 개인정보가 유출되지 않도록 유의하시고 게시물을 포함한 타인의 개인정보가 훼손되지 않도록 유의해 주시기 바랍니다. 만약 이러한 의무를 다하지 못하고 타인의 개인정보를 침해하거나 훼손할 시에는 관련 법령에 따라 처벌 받을 수 있습니다.
          </p>

          <p>[6. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항]</p>
          <p>
            (가). 회원 인증에 쿠키(Cookie)를 사용하는 것에 대한 안내 'IT리뷰모아'는 고객님에게 보다 적절하고 유용한 서비스를 제공하기 위하여 고객님이 로그인하는 회원 인증 부분에서 쿠키를 사용합니다. 쿠키란 웹사이트에 접속하면 자동으로 고객님의 웹 브라우저에 생성되는 텍스트 파일을 말합니다. 이 쿠키는 고객님께서 로그아웃(Log-out) 후, 웹 브라우저를 닫으면 자동으로 삭제됩니다.

            'IT리뷰모아'는 서비스에 배너 광고를 표시하기 위해 외부 광고 업체를 활용할 수 있습니다. 그들의 서비스의 일부로써, 그들은 귀하의 장치에 별도의 쿠키를 저장할 수 있습니다. 'IT리뷰모아'는 제3의 광고 서버에 귀하의 어떠한 개인정보나 구매와 관련된 정보를 제공하지 않습니다. 'IT리뷰모아'와 제3의 광고 서버는 IP주소, 브라우저 종류, 접속한 서버, 서버의 위치, 특정 광고에 반응을 했는지 등에 대한 기타 정보만을 수집하고 활용합니다. 다른 광고주들도 유사한 방식으로 우리 서비스에 광고를 게재할 수 있으나 그들에게 개인정보를 제공하지 않습니다.

            [쿠키 저장 거부 방법]
            웹 브라우저의 설정을 변경하여 쿠키의 저장을 허용하거나 거부할 수 있습니다. 다음은 Internet Explorer에서의 쿠키 설정에 접근하는 절차입니다.
            * 웹 브라우저 상단의 [도구] → [인터넷 옵션] → [개인 정보] → [설정]의 [고급] → [쿠키]
            단, 쿠키의 저장을 거부할 경우에는 로그인 및 로그인이 필요한 서비스의 이용에 제한이 생길 수 있으며, 이에 대한 책임은 전적으로 고객님에게 있음을 유의하시기 바랍니다.
          </p>

          <p>[7. 개인정보 보호책임자]</p>
          <p>
            'IT리뷰모아'는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 'IT리뷰모아'의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 'IT리뷰모아'로 문의하실 수 있으며, 'IT리뷰모아'는 이용자의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.

            개인정보 보호책임자
            책임자 : 'IT리뷰모아'
            연락처 : fg43irks@naver.com
            고객님은 'IT리뷰모아'의 서비스 이용 중 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자에게 문의하실 수 있습니다. 'IT리뷰모아'는 고객님의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

            개인정보분쟁조정위원회 (http://kopico.go.kr / 국번없이 1833-6972)
            개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
            대검찰청 사이버수사과 (http://www.spo.go.kr / 국번없이 1301)
            경찰청사이버수사국 (https://ecrm.cyber.go.kr / 국번없이 182)

            'IT리뷰모아'는 정보의 분실, 도난, 누출, 변조, 외부로부터의 공격, 해킹 등을 막기 위하여 개인정보관리 시스템에 대해 외부로부터 침입을 차단하고 보호하는 보안조치를 이용하고 있으며, 문제점이 발견될 경우 신속하게 시정조치를 하고 있습니다.
          </p>

          <p>[8. 개인정보 처리방침 변경]</p>
          <p>본 개인정보처리방침은2024년 5월 01일부터 적용됩니다.</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>