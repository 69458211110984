<template>
  <v-card
      class="mx-auto my-12"
      max-width="550"
  >

    <v-img
        height="550"
        :src="`${book.imgPath}` ? `${book.imgPath}` : '/assets/noImg.gif'"
        cover
    ></v-img>

    <v-card-item>
      <v-card-title style="font-weight: bold">{{book.title}}</v-card-title>

      <v-card-subtitle>
        <span class="me-1">저자 : {{book.author}}</span><br>
        <span class="me-1">출판사 : {{book.publisher}}</span><br>
        <span class="me-1">평점 : {{book.rating}}</span><br>
      </v-card-subtitle>
    </v-card-item>

    <v-card-text>
      <v-row
          align="center"
          class="mx-0"
      >
        <v-rating
            :model-value="`${book.rating}`/2"
            color="amber"
            density="compact"
            size="small"
            half-increments
            readonly
        ></v-rating>

        <div class="text-grey ms-4">
          {{book.rating}}
        </div>
      </v-row>

      <div class="my-3 text-subtitle-1 d-flex align-start">
        <div class="text-decoration-line-through">
          {{book.regular_price}}
        </div>

        <span style="margin-left: 10px"/>

        <div class="text-red">
          {{book.selling_price}}원
        </div>
      </div>

<!--      <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>-->
    </v-card-text>

    <v-card-actions>
      <v-btn
          color="deep-dark-accent-4"
          text="리뷰보기"
          style="font-weight: bold"
          variant="flat"
          @click="dialogPopupStatus = true, fetchReviews(`${book.book_id}`, `${book.site}`, `${book.isbn}`)"
      ></v-btn>
    </v-card-actions>

    <v-dialog
        v-model="dialogPopupStatus"
        width="auto"
    >
      <v-card
          max-width="800"
          prepend-icon="mdi-update"
          :title="`[${book.title}]`"
      >

        <template v-if="reviewContentPopupStatus === true">
          <ReviewTitleList v-bind:book="book" v-bind:reviewList="reviewList"/>
        </template>

        <template v-else>
          <p>리뷰가 없습니다.</p>
        </template>

        <div class="text-xs-center">
          <v-pagination
              v-model="currentPage"
              :length="totalPages"
              :total-visible="5"
              @update:modelValue="handlePageChange"
          >
          </v-pagination>
        </div>

        <template v-slot:actions>
          <v-btn
              class="ms-auto"
              text="CLOSE"
              @click="dialogPopupStatus = false"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import lib from "@/scripts/lib";
import {ref, watch} from "vue";
import axios from "axios";
import ReviewTitleList from "@/components/ReviewTitleList";

export default {
  name: "Card",
  components: {ReviewTitleList},
  props: {
    book: Array
  },

  setup(props) {

    const reviewList = ref([]);                                        // 리뷰 데이터
    const currentPage = ref(0);
    const totalPages = ref(0);
    const totalReviewCount = ref(0);
    const dialogPopupStatus = ref(false);
    const reviewContentPopupStatus = ref(false);


    // book.rating 값을 별표로 변환하는 메서드
    const convertToStars = (rating) => {
      const maxRating = 10;                                   // 만점
      const stars = Math.round((rating / maxRating) * 5); // 5개 별표로 변환
      return '★'.repeat(stars) + '☆'.repeat(5 - stars); // 별표로 변환된 문자열 반환
    };


    // 리뷰 데이터 가져오기
    const fetchReviews = (book_id, site, isbn) => {

      const params = {
        page: currentPage.value -1,
        // size: 5, // 페이지당 아이템 수
        bookId: book_id,
        isbn: isbn,
        reviewSite: site
      };

      axios.get(`/api/v1/review/reviewList`, { params, headers: {
          "Content-Type": "application/json",
        }, })
          .then((res) => {
            if (res.data.resultCode === "OK") {
              reviewList.value = res.data.data;
              totalPages.value = res.data.reviewPaginationDTO.totalPageCnt;
              totalReviewCount.value = res.data.reviewPaginationDTO.totalListCnt;

              if (totalReviewCount.value == 0) {
                reviewContentPopupStatus.value = false;
                return;
              } else {
                reviewContentPopupStatus.value = true;
              }
            }
          })
          .catch((err) => {
            if (err.message.indexOf('Network Error') > -1) {
              alert('네트워크가 원활하지 않습니다.\n 잠시 후, 다시 시도해주세요.')
            }
          });
    };

    // 페이지 이동 이벤트 핸들러
    const handlePageChange = (page) => {
      currentPage.value = page;
      console.log("currentPage :  " + currentPage.value);
    };

    watch(currentPage, () => {
      fetchReviews(props.book.book_id, props.book.site);
    });

    return {
      lib,
      reviewList,
      fetchReviews,
      convertToStars,

      dialogPopupStatus,
      reviewContentPopupStatus,

      currentPage,
      totalPages,
      totalReviewCount,
      handlePageChange,
    };
  }
}
</script>

<style scoped>
.card .img {
  display: inline-block;
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
}

.card .card-body .price {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

</style>