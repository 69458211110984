<template>
  <div class="modal fade" id="announcementModal" tabindex="-1" aria-labelledby="announcementModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" id="announcementModalLabel">공지사항</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <p>특정 도서 사이트들의 리뷰데이터 수집이 막혀있어 가까운 시일 내에 타 사이트의 리뷰 데이터를 업데이트 하도록 하겠습니다.</p>


          <p>이 외에 회원 서비스 및 커뮤니티 기능들과 UI 개선 작업들은 추후 적용될 예정입니다.</p>

          &nbsp;
          <p># 업데이트 현황 #</p>
          <p>[2024.05.28]</p>
          <p>- 기존 로그인 & 게시판 서비스 기능 제거</p>
          <p>- 익명갤러리 기능 추가 (로그인 필요 X)</p>

          &nbsp;
          <p>[2024.06.06]</p>
          <p>- 도서 전체 조회 시, 특정 도서들이 중복으로 조회 이슈 조치 완료</p>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Announcement"
}
</script>

<style scoped>

</style>