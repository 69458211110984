import Home from "@/pages/Home";
import {createRouter, createWebHistory} from "vue-router";
import Forum from "@/components/DashBoard/Forum";
import ForumPost from "@/components/DashBoard/ForumPost";
import ForumDetail from "@/components/DashBoard/ForumDetail";
import ForumModify from "@/components/DashBoard/ForumModify";

const routes = [
    {path: '/', component: Home},
    {path: '/forumList', component: Forum},
    {path: '/forum/post', component: ForumPost},
    {path: '/forum/detail', component: ForumDetail},
    {path: '/forum/modify', component: ForumModify, props: true},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;