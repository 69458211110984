<template>
  <v-form>
    <v-container>
      <v-row justify="center">
        <v-col
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="nickName"
              :rules="[rules.nickNameRequired, rules.nickNameLength]"
              label="닉네임"
              variant="outlined"
              maxlength="20"
              max-width="100%"
              counter
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            sm="3"
        >
          <v-text-field
              v-model="password"
              :rules="[rules.passwordRequired, rules.passwordLength]"
              maxlength="15"
              label="비밀번호"
              variant="outlined"
              type="password"
              max-width="100%"
              counter
          ></v-text-field>
        </v-col>
      </v-row>

      <v-responsive
          class="mx-auto"
          max-width="58%"
          style="margin-top: 1%;"
      >
        <v-text-field
            v-model="title"
            :rules="[rules.titleRequired, rules.titleLength]"
            hide-details="auto"
            label="제목입력"
            variant="outlined"
            max-width="100%"
        ></v-text-field>
      </v-responsive>
    </v-container>
  </v-form>

  <div id="editor" ref="editor" style="max-width: 49%"></div>
  <v-col class="text-center">
    <v-btn variant="outlined" @click="modifyPost()">수정</v-btn>
  </v-col>
</template>

<script>
import {onMounted, ref} from "vue";
import {Editor} from "@toast-ui/editor";
import colorPlugin from "@toast-ui/editor-plugin-color-syntax";
import axios from "axios";
import router from "@/scripts/router";

export default {
  name: "ForumModify",

  setup() {

    const postId = ref(history.state.postId);
    const nickName = ref(history.state.nickName);
    const password = ref(history.state.password);
    const title = ref(history.state.title);
    const content = ref(history.state.content);

    const nickNameRequired = value => !!value || 'Required.';
    const nickNameLength = value => value.length <= 20 || '최대 20글자 닉네임만 가능합니다';
    const passwordRequired = value => !!value || 'Required.';
    const passwordLength = value => value.length <= 15 || '비밀번호는 최대 15자 입력 가능합니다';
    const titleRequired = value => !!value || 'Required.';
    const titleLength = value => value.length <= 30 || '제목은 최대 30자 입력 가능합니다';

    const rules = {
      nickNameRequired,
      passwordRequired,
      titleRequired,

      nickNameLength,
      passwordLength,
      titleLength
    };

    const editor = ref();
    const editorValid = ref();

    onMounted(() => {
      editorValid.value = new Editor({
        el: editor.value,
        height: '500px',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        plugins: [colorPlugin],
        language: "ko-KR",
        initialValue: content.value,
        events: {
          change: () => {
            content.value = editorValid.value.getMarkdown();

          }
        },
      });
    });

    const modifyPost = () => {
      // 게시물 등록 예외처리 (CKEditor5 특성 상, 양 끝에 쌍 따옴표가 붙어서 제거 필요)
      const params = {
        postId: postId.value,
        title: title.value,
        content: content.value,
        nickName: nickName.value,
        password: password.value
      };

      console.log("postId : " + postId.value);
      console.log("title : " + title.value);
      console.log("content : " + content.value);
      console.log("nickName : " + nickName.value);
      console.log("password : " + password.value);

      // 게시물 수정 처리 로직
      axios.put(`/api/v1/forum/modify`, params,{
        headers: {"Content-Type": "application/json"}
      })
          .then((response) => {
            // 게시물 등록 완료
            console.log(response.data);
            window.alert("게시물 등록 완료");
            router.push({path:"/forumList"})
          })
          .catch((error) => {
            console.error(error.response.data);

            // HTTP 상태 코드에 따라 분기
            if (error.response.status === 500) {
              // 500 INTERNAL SERVER ERROR : 로그인 없이 작성할 경우
              window.alert("로그인을 먼저 진행한 후, 작성해주세요");
            } else {
              // 다른 상태 코드에 대한 처리
              window.alert("게시물 등록에 실패했습니다. 다시 시도해주세요");
            }
          });
    };

    return {
      editor,
      editorValid,
      modifyPost,

      postId,
      title,
      content,
      nickName,
      password,
      rules,
    }
  }
}
</script>

<style scoped>
#editor {
  padding-top: 1%;
  margin: 0 auto;
}

</style>