<template>
  <div style="margin-left: 26%; margin-top: 2%; font-size: 25px; font-weight: bold;">익명갤러리</div>
  <hr class="hr3" style="margin: auto; height:2px; border:none; background-color:#000000; width: 48%;"/>

  <div class="memberInfoDiv">
    <p class="title" style="margin-top: 1%;">{{title}}</p>
    <p>
      <span>{{nickName}}</span> |
      <span>{{registeredAt}}</span>
    </p>

    <p>
      <span>조회 {{viewCount}}</span>
<!--      <span>추천수 : {{re}}</span>-->
    </p>
  </div>

  <hr style="margin: auto; width: 48%; height:1px; border:none; background-color:#bbbbbb;"/>

  <div class="contentArea" style="margin-top: 2%;">
    {{content}}
  </div>


  <p style="margin-left: 26%; margin-top: 5%;">전체 댓글 {{totalCommentCount}}개</p>
  <hr class="hr3" style="margin: auto; height:2px; border:none; background-color:#000000; width: 48%;"/>

  <v-card class="mx-auto"
          v-for="comment in commentList" :key="comment.id"
          max-width="48%"
          hover
          :title="`${comment.nickName}`"
  >
    <template v-slot:append>
      <div class="me-n2 d-flex">
        <v-btn
            density="comfortable"
            icon="$close"
            variant="plain"
            @click="handleDeleteCommentForm(comment)"
        >
        </v-btn>
      </div>
    </template>

    <!-- 댓글 삭제 비밀번호 입력 폼 시작 -->
    <div v-if="comment.toggleDeleteCommentForm">
      <v-row class="justify-end">
        <v-text-field
            label="비밀번호 입력"
            variant="outlined"
            hint="댓글 삭제를 위한 비밀번호를 입력하세요"
            max-width="20%"
            type="password"
            v-model="commentPassword"
        >
        </v-text-field>
        <div class="text-xs-center">
          <v-btn outline color="indigo" height="70%" @click="deleteComment(comment.id)">등록</v-btn>
        </div>
      </v-row>
    </div>
    <!-- 댓글 삭제 비밀번호 입력 폼 끝 -->

    <v-card-item>
      <v-card-subtitle> {{comment.registeredAt}} </v-card-subtitle>
    </v-card-item>

    <v-card-text>
      {{comment.comment}}
      <v-row class="justify-end">
        <v-btn variant="outlined" @click="toggleReply(comment.id)">답글 작성</v-btn>
      </v-row>

    </v-card-text>


    <!-- 대댓글 렌더링 시작 -->
    <div v-if="comment.childComments && comment.childComments.length > 0">
      <v-card v-for="childComment in comment.childComments" :key="childComment.id"
                class="justify-end"
                v-model="reply"
                max-width="100%"
                variant="tonal"
                :title=" '↳ ' + `${childComment.nickName}`"
        >
        <template v-slot:append>
          <div class="me-n2">
            <v-btn
                density="comfortable"
                icon="$close"
                variant="plain"
                @click="handleDeleteReplyForm(childComment)"
            ></v-btn>
          </div>
        </template>

        <!-- 대댓글 삭제 비밀번호 입력 폼 시작 -->
        <div v-if="childComment.toggleDeleteReplyForm">
          <v-row class="justify-end">
            <v-text-field
                label="비밀번호 입력"
                variant="outlined"
                hint="댓글 삭제를 위한 비밀번호를 입력하세요"
                max-width="20%"
                type="password"
                v-model="replyPassword"
            >
            </v-text-field>
            <div class="text-xs-center">
              <v-btn outline color="indigo" height="70%" @click="deleteReply(childComment, comment.id)">등록</v-btn>
            </div>
          </v-row>
        </div>
        <!-- 대댓글 삭제 비밀번호 입력 폼 끝 -->


          <v-card-item>
            <v-card-subtitle> {{formatDateTime(childComment.registeredAt)}} </v-card-subtitle>
          </v-card-item>

          <v-card-text> {{childComment.reply}} </v-card-text>
      </v-card>
    </div>
    <!-- 대댓글 렌더링 끝 -->


    <!-- 대댓글 작성 폼 시작 -->
    <div class="replyArea" v-if="comment.toggleReplyingForm">
      <v-row>
        <v-col
            cols="1"
            sm="3"
        >
          <v-text-field
              label="닉네임"
              v-model="replyNickName"
              :rules="[rules.replyNickNameRequired, rules.replyNickNameLength]"
              variant="outlined"
              height="40px"
          ></v-text-field>
          <v-text-field
              label="비밀번호"
              v-model="replyPassword"
              :rules="[rules.replyPasswordRequired, rules.replyPasswordLength]"
              variant="outlined"
              type="password"
          ></v-text-field>
        </v-col>

        <v-col
            cols="8"
            sm="9"
        >
          <v-textarea
              label="대댓글 입력"
              rows="4"
              variant="outlined"
              auto-grow
              shaped
              v-model="reply"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-container>
        <v-row justify="end">
          <v-btn variant="outlined" @click="addReply(comment)">등록</v-btn>
        </v-row>
      </v-container>
    </div>
    <!-- 대댓글 작성 폼 끝 -->

  </v-card>

  <!-- 페이지네이션 -->
  <div class="text-xs-center">
    <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="5"
        @update:modelValue="handlePageChange"
    >
    </v-pagination>
  </div>

  <div class="commentArea">
    <v-row>
      <v-col
          cols="1"
          sm="3"
      >
        <v-text-field
            label="닉네임"
            v-model="commentNickName"
            :rules="[rules.commentNickNameRequired, rules.commentNickNameLength]"
            variant="outlined"
            height="40px"
        ></v-text-field>
        <v-text-field
            label="비밀번호"
            v-model="commentPassword"
            :rules="[rules.commentPasswordRequired, rules.commentPasswordLength]"
            variant="outlined"
            type="password"
        ></v-text-field>
      </v-col>

      <v-col
          cols="8"
          sm="9"
      >
        <v-textarea
            label="댓글 입력"
            rows="4"
            variant="outlined"
            auto-grow
            shaped
            v-model="comment"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-container>
      <v-row justify="end">
        <v-btn variant="outlined" @click="addComment">등록</v-btn>
      </v-row>
    </v-container>
  </div>

  <hr style="margin: auto; width: 48%; height:1px; border:none; background-color:#bbbbbb;"/>

  <v-container style="max-width: 49.3%">
    <v-row justify="end">
      <v-col cols="auto" class="mr-auto">
        <v-btn variant="outlined" @click="gotoForumListPage">전체글</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn variant="outlined" @click="toggleModifyPostForm = true">수정</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn variant="outlined" @click="toggleDeletePostForm = true">삭제</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn variant="outlined" @click="gotoCreatePostPage">글쓰기</v-btn>
      </v-col>
    </v-row>
  </v-container>

  <!-- 게시글 삭제 버튼 클릭 시, 비밀번호 팝업 창 시작 -->
  <div class="text-center pa-4" v-if="toggleDeletePostForm">

    <v-dialog
        v-model="toggleDeletePostForm"
        width="auto"
    >
      <v-card
          prepend-icon="mdi-key"
          title="비밀번호 입력"
      >
        <v-text-field
            label="비밀번호"
            v-model="postDeletePassword"
            variant="outlined"
            type="password"
        ></v-text-field>
        <template v-slot:actions>
          <v-btn
              class="ms-auto"
              text="OK"
              @click="deletePost"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
  <!-- 게시글 삭제 버튼 클릭 시, 비밀번호 팝업 창 끝 -->


  <!-- 게시글 수정 버튼 클릭 시, 비밀번호 팝업 창 시작 -->
  <div class="text-center pa-4" v-if="toggleModifyPostForm">

    <v-dialog
        v-model="toggleModifyPostForm"
        width="auto"
    >
      <v-card
          prepend-icon="mdi-key"
          title="비밀번호 입력"
      >
        <v-text-field
            label="비밀번호"
            v-model="postModifyPassword"
            variant="outlined"
            type="password"
        ></v-text-field>
        <template v-slot:actions>
          <v-btn
              class="ms-auto"
              text="OK"
              @click="checkModifyPassword"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
  <!-- 게시글 수정 버튼 클릭 시, 비밀번호 팝업 창 끝 -->

</template>

<script>
import axios from "axios";
import router from "@/scripts/router";
import {computed, ref} from "vue";
import store from "@/scripts/store";

export default {
  name: "ForumDetail",

  setup() {

    // store.js에 저장한 게시글 상세 정보를 불러옴.
    const postData = computed(() => store.getters.getPostData);
    const postId = ref(postData.value.postId);
    const title = ref(postData.value.title);
    const content = ref(postData.value.content);
    const nickName = ref(postData.value.nickName);
    const password = ref('');
    const registeredAt = ref(postData.value.registeredAt);
    const viewCount = ref(postData.value.viewCount);
    const commentNickName = ref('');
    const commentPassword = ref('');

    // 댓글 페이징
    const currentPage = ref(1);
    const totalPages = ref(1);

    const commentList = ref([]);
    const childComments = ref([]);

    // // 댓글 작성
    const comment = ref('');
    const commentRegisteredAt = ref('');
    const totalCommentCount = ref(0);

    // 대댓글 관련 변수
    const toggleReplyingForm = ref(false);
    const reply = ref('');
    const replyNickName = ref('');
    const replyPassword = ref('');

    // 댓글 삭제 비밀번호 form 토글 관련
    const toggleDeleteCommentForm = ref(false);

    // 대댓글 삭제 비밀번호 form 토글 관련
    const toggleDeleteReplyForm = ref(false);

    // 게시글 삭제 비밀번호 form 토글 관련
    const toggleDeletePostForm = ref(false);
    const postDeletePassword = ref('');

    // 게시글 수정 비밀번호 관련
    const toggleModifyPostForm = ref(false);
    const postModifyPassword = ref('');

    // 대/댓글 닉네임, 비밀번호 입력 검증
    const commentNickNameRequired = value => !!value || 'Required.';
    const commentNickNameLength = value => value.length <= 20 || '최대 20글자 닉네임만 가능합니다';
    const commentPasswordRequired = value => !!value || 'Required.';
    const commentPasswordLength = value => value.length <= 15 || '비밀번호는 최대 15자 입력 가능합니다';

    const replyNickNameRequired = value => !!value || 'Required.';
    const replyNickNameLength = value => value.length <= 20 || '최대 20글자 닉네임만 가능합니다';
    const replyPasswordRequired = value => !!value || 'Required.';
    const replyPasswordLength = value => value.length <= 15 || '비밀번호는 최대 15자 입력 가능합니다';

    const rules = {
      commentNickNameRequired,
      commentPasswordRequired,
      replyNickNameRequired,
      replyPasswordRequired,

      commentNickNameLength,
      commentPasswordLength,
      replyNickNameLength,
      replyPasswordLength
    };


    // YYYY.MM.DD HH:MM:SS 형식으로 날짜 변환하는 함수
    const formatDateTime = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${year}.${month}.${day} ${hours}:${minutes}`.toString();
    };

    // 페이지 이동 이벤트 핸들러
    const handlePageChange = (page) => {
      currentPage.value = page;
      console.log("currentPage :  " + currentPage.value);
    };

    // // 댓글 관련
    // const replyId = ref(0);
    // const replying = ref(false);
    // const reply = ref('');


    // // 대댓글 작성 입력 관련
    // const childReplying = ref(false);

    const checkModifyPassword = () => {
      toggleModifyPostForm.value = false;

      console.log("postId : " + postId.value);
      console.log("postModifyPassword : " + postModifyPassword.value);

      axios.get(`/api/v1/forum/validate`, {
        params: {
          postId: postId.value,
          password: postModifyPassword.value
        }
      })
          .then(() => {
            // 가져온 게시글 비밀번호
            window.alert("비밀번호 인증 완료");
            router.push({
              path: "/forum/modify",
              state: {
                postId: postId.value,
                title: title.value,
                content: content.value,
                nickName: nickName.value,
                password: postModifyPassword.value
              }
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              window.alert("입력하신 비밀번호와 해당 게시글의 비밀번호가 일치하지 않습니다.");
              // router.push({path: "/"});
            } else if (err.response.status == 500) {
              window.alert("서버 오류 발생");
              // router.push({path: "/"});
            } else {
              console.error("err message : " + err);
              window.alert("해당 글의 작성자가 아니거나 수정하려는 글이 존재하지 않습니다.");
            }
          });
    }


    // 게시글 삭제 버튼
    const deletePost = () => {
      toggleDeletePostForm.value = false;

      console.log("postId : " + postId.value);
      console.log("postDeletePassword : " + postDeletePassword.value);

      const params = {
        postId: postId.value,
        password: postDeletePassword.value
      };

      axios.delete(`/api/v1/forum/delete`, {
        data: params,
        headers: {"Content-Type": "application/json"}
      })
          .then(() => {
            // 가져온 게시글 삭제
            window.alert("게시글 삭제 완료");
            router.push({path: "/forumList"});
          })
          .catch((err) => {
            if (err.response.status === 401) {
              window.alert("입력하신 비밀번호와 해당 게시글의 비밀번호가 일치하지 않습니다.");
              // router.push({path: "/"});
            } else if (err.response.status == 500) {
              window.alert("서버 오류 발생");
              // router.push({path: "/"});
            } else {
              console.error("err message : " + err);
              window.alert("해당 글의 작성자가 아니거나 삭제할 글이 존재하지 않습니다.");
            }
          });
    };

    // 게시글 수정 페이지 이동
    // const modifyPost = () => {
    //   toggleModifyPostForm.value = false;
    //
    //   console.log("postId : " + postId.value);
    //   console.log("postModifyPassword : " + postModifyPassword.value);
    //
    //   const params = {
    //     postId: postId.value,
    //     password: postModifyPassword.value
    //   };
    //
    //   axios.delete(`/api/v1/forum/delete`, {
    //     data: params,
    //     headers: {"Content-Type": "application/json"}
    //   })
    //       .then(() => {
    //         // 가져온 게시글 삭제
    //         window.alert("게시글 삭제 완료");
    //         router.push({path: "/forumList"});
    //       })
    //       .catch((err) => {
    //         if (err.response.status === 401) {
    //           window.alert("입력하신 비밀번호와 해당 게시글의 비밀번호가 일치하지 않습니다.");
    //           // router.push({path: "/"});
    //         } else if (err.response.status == 500) {
    //           window.alert("서버 오류 발생");
    //           // router.push({path: "/"});
    //         } else {
    //           console.error("err message : " + err);
    //           window.alert("해당 글의 작성자가 아니거나 삭제할 글이 존재하지 않습니다.");
    //         }
    //       });
    // };


    // 해당 게시글의 (대)댓글 가져오기
    const getCommentList = () => {
      console.log("postId2 : " + postId.value);

      axios.get(`/api/v1/comment/reply/${postId.value}`)
          .then((res) => {
            // 코멘트 리스트의 각 항목에서 필요한 속성들만 가져와서 commentList에 할당
            commentList.value = res.data.map(comment => ({
              id: comment.id,
              nickName: comment.nickName,
              comment: comment.reply,
              registeredAt: formatDateTime(comment.registeredAt),
              childComments: comment.childComments,
              viewCount: comment.viewCount
            }))

            // 작성된 댓글 갯수
            // totalCommentCount.value = res.data.totalElements;
          })
          .catch((err) => {
            console.error('댓글 리스트를 가져오는 중 오류 발생:', err);
          });
    };

    const getCommentCounting = () => {

      axios.get(`/api/v1/comment/count/${postId.value}`)
          .then((res) => {
            totalCommentCount.value = res.data;
          })
          .catch((err) => {
            console.error("댓글 개수를 가져오는 중 오류 발생 : ", err);
          })
    }

    // 댓글 달기 작성
    const addComment = () => {

      console.log("postId : " + postId.value);
      console.log("comment : " + comment.value);
      console.log("commentNickName : " + commentNickName.value);
      console.log("commentPassword : " + commentPassword.value);

      const params = {
        postId: postId.value,
        content: comment.value,
        nickName: commentNickName.value,
        password: commentPassword.value,
      };

      if(commentNickName.value.length == 0) {
        window.alert("닉네임을 입력하세요");
        return;
      }
      else if(commentPassword.value.length == 0) {
        window.alert("비밀번호를 입력하세요");
        return;

      } else if(comment.value.length == 0) {
        window.alert("댓글 내용을 입력하세요");
        return;
      }

      axios.post(`/api/v1/comment/create`, params,{
        headers: {"Content-Type": "application/json"}
      })
          .then(() => {
            window.alert("댓글 작성 완료");
          })
          .catch(() => {
            window.alert("작성 중 오류가 발생했습니다");
          })

      // 댓글 작성 후, 댓글 리스트 최신화
      getCommentList();
      getCommentCounting();
    };

    // 댓글 삭제 폼 플래그 변수 제어 메소드
    const handleDeleteCommentForm = (comment) => {
      comment.toggleDeleteCommentForm = !comment.toggleDeleteCommentForm;
      console.log("toggleDeleteCommentForm : " + toggleDeleteCommentForm.value);
    }

    // 대댓글 삭제 폼 플래그 변수 제어 메소드
    const handleDeleteReplyForm = (childComment) => {
      childComment.toggleDeleteReplyForm = !childComment.toggleDeleteReplyForm;
      console.log("toggleDeleteReplyForm : " + toggleDeleteReplyForm.value);
    }

    // 대댓글 달기 작성
    const addReply = (comment) => {
      const params = {
        postId: postId.value,
        parentId: comment.id,
        reply: reply.value,
        nickName: replyNickName.value,
        password: replyPassword.value,
      };

      if(replyNickName.value.length == 0) {
        window.alert("닉네임을 입력하세요");
        return;
      }
      else if(replyPassword.value.length == 0) {
        window.alert("비밀번호를 입력하세요");
        return;

      } else if(reply.value.length == 0) {
        window.alert("댓글 내용을 입력하세요");
        return;
      }

      console.log("replyNickName : " + replyNickName.value);
      console.log("replyPassword : " + replyPassword.value);
      console.log("comment.id : " + comment.id);

      axios.post(`/api/v1/comment/reply/create`, params, {
        headers: {"Content-Type": "application/json"}
      })
          .then(() => {
            window.alert("대댓글 작성 완료");
          })
          .catch(() => {
            window.alert("대댓글 작성 중 오류가 발생했습니다");
          })

      // 댓글 작성 후, 댓글 리스트 최신화
      getCommentList();
      getCommentCounting();
    };


    // 대댓글 폼 토글 메소드
    const toggleReply = (commentId) => {
      const targetComment = commentList.value.find(comment => comment.id === commentId);
      targetComment.toggleReplyingForm = !targetComment.toggleReplyingForm;
      console.log("toggleReplyingForm : " + targetComment.toggleReplyingForm);
    };

    // 댓글 삭제
    const deleteComment = (commentId) => {
      const params = {
        commentId: commentId,
        password: commentPassword.value
      };

      console.log("commentId : " + commentId);
      console.log("password : " + commentPassword.value);

      axios.delete(`/api/v1/comment/delete`, {
        data: params,
        headers: {"Content-Type": "application/json"}
      })
          .then(() => {
            window.alert("댓글 삭제 완료");
          })
          .catch((error) => {
            if(error.response) {
              // 서버 응답이 2xx 외의 상태 코드인 경우
              if(error.response.status == 500) {
                window.alert("댓글 삭제 중 오류가 발생했습니다. 패스워드가 올바른지 확인 바랍니다");
              } else {
                window.alert("댓글 삭제 중 오류가 발생했습니다. :" + error.response.data.message);
              }
            }
            else if(error.request) {
              // 요청이 전송되었으나 응답을 받지 못한 경우
              window.alert("댓글 삭제 요청 중 오류가 발생했습니다. 네트워크 문제일 수 있습니다.");
            } else {
              // 오류를 발생시킨 요청 설정 문제인 경우
              window.alert("요청 설정 중 오류가 발생했습니다. : " + error.message);
            }
          })

      // 댓글 작성 후, 댓글 리스트 최신화
      getCommentList();
      getCommentCounting();
    }


    // 대댓글 삭제
    const deleteReply = (childComment, commentId) => {
      const params = {
        commentId: childComment.id,
        parentId: commentId,
        password: replyPassword.value
      };

      console.log("childCommentId : " + childComment.id);
      console.log("commentId : " + commentId);
      console.log("password : " + replyPassword.value);

      axios.delete(`/api/v1/comment/reply/delete`, {
        data: params,
        headers: {"Content-Type": "application/json"}
      })
          .then(() => {
            window.alert("대댓글 삭제 완료");
          })
          .catch((error) => {
            if(error.response) {
              // 서버 응답이 2xx 외의 상태 코드인 경우
              if(error.response.status == 500) {
                window.alert("대댓글 삭제 중 오류가 발생했습니다. 패스워드가 올바른지 확인 바랍니다");
              } else {
                window.alert("대댓글 삭제 중 오류가 발생했습니다. :" + error.response.data.message);
              }
            }
            else if(error.request) {
              // 요청이 전송되었으나 응답을 받지 못한 경우
              window.alert("대댓글 삭제 요청 중 오류가 발생했습니다. 네트워크 문제일 수 있습니다.");
            } else {
              // 오류를 발생시킨 요청 설정 문제인 경우
              window.alert("요청 설정 중 오류가 발생했습니다. : " + error.message);
            }
          })

      // 댓글 작성 후, 댓글 리스트 최신화
      getCommentList();
      getCommentCounting();
    }


    // 전체글 클릭 시, 게시판 페이지로 이동
    const gotoForumListPage = () => {
      router.push({path: "/forumList"});
    }

    const gotoCreatePostPage = () => {
      router.push({path: "/forum/post"});
    }

    // 댓글 카운팅 실시간 추적
    // watch(() => totalCommentCount.value, () => {
    //   getCommentCounting();
    // });

    // 초기 페이지 이동 시, 데이터 가져오는 메소드
    getCommentList();
    getCommentCounting();

    return {
      checkModifyPassword,
      deletePost,

      currentPage,
      totalPages,
      handlePageChange,

      registeredAt,
      viewCount,

      title,
      content,
      nickName,
      password,
      formatDateTime,

      commentNickName,
      commentPassword,

      commentList,
      childComments,
      comment,
      commentRegisteredAt,
      totalCommentCount,
      getCommentCounting,

      addComment,
      deleteComment,
      addReply,
      deleteReply,
      getCommentList,

      toggleReply,
      toggleReplyingForm,
      toggleDeleteCommentForm,
      handleDeleteCommentForm,
      toggleDeleteReplyForm,
      handleDeleteReplyForm,
      toggleDeletePostForm,
      postDeletePassword,

      toggleModifyPostForm,
      postModifyPassword,

      reply,
      replyNickName,
      replyPassword,

      gotoForumListPage,

      gotoCreatePostPage,

      rules,
    }
  }
}
</script>

<style scoped>
.memberInfoDiv {
  margin-left: 26%;
}

.contentArea {
  margin-left: 26%;
}

.replyArea {
  max-width: 100%;
  margin: auto;
}

.commentArea {
  margin: auto;
  width: 48%;
}

</style>